import { createStore, Store } from 'redux';
import reducers from 'redux/reducers';
import { IProfileState } from 'types/profile.type';
import { IProductStockState } from 'types/productStock.type';
import { IProductState } from 'types/product.type';

export interface ApplicationState {
  profile: IProfileState,
  productStock: IProductStockState,
  product: IProductState
}

const store: Store<ApplicationState> = createStore(
  reducers
);

export default store;