import axios from 'axios';
import { toast } from 'react-toastify';
import { removeToken } from 'helpers/auth';

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 403) {
    removeToken()
    window.location.href = '/';
  }
  if (error.response && error.response.data) {
    toast.error(error.response.data.message || 'Failed performing action', {
      position: toast.POSITION.TOP_CENTER,
      pauseOnHover: false,
    });
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});