import axios from 'axios';
import { SignInRequest, VerifyOTPRequest } from 'types/auth.type';
import { getHeaders } from 'helpers/auth';
import 'helpers/interceptor';

export function apiSignIn(data: SignInRequest) {
  return axios({
    url: process.env.REACT_APP_ENDPOINT + '/auth-customer/signin',
    method: 'POST',
    data
  });
}

export function apiVerifyOTP(data: VerifyOTPRequest){
  return axios({
    url: process.env.REACT_APP_ENDPOINT + '/auth-customer/verify',
    method: 'POST',
    data
  });
}

export async function apiSignOut() {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_ENDPOINT + '/auth-customer/signout',
    method: 'POST',
    headers
  });
}