import {
	IProfileState,
	ProfileTypes
} from 'types/profile.type';
import {
	EAroReferralType
} from 'types/aroReferral.type';

import { Reducer } from 'redux';

const initProfile = {
    distributorId: '',
    customerId: '',
    labelName: '',
    voucherParticipation: false,
    customer: {
        status: '',
        phoneNumber: ''
    },
    aroBankAccount: {
        accountNumber: '',
        accountName: '',
        bank: {
            bankId: '',
            bankName: ''
        }
    },
    distributor: {
        distributorId: '',
        name: '',
        address: '',
        coordinate: ''
    },
    aroShipmentDiscount: [
        {
            amount: 0
        }
    ],
    referralDiscount: {
        aroReferralId: '',
        discountNote: '',
        discountFormula: '',
        aroReferralType: EAroReferralType.REFERRAL
    },
    nonReferralDiscount: {
        aroReferralId: '',
        discountNote: '',
        discountFormula: '',
        aroReferralType: EAroReferralType.NON_REFERRAL,
    }
}

const initialState: IProfileState = {
	isLoadingProfile: true,
	data: initProfile,
	error: {}
}

export const profile: Reducer<IProfileState> = (state = initialState, action)=>{
	switch(action.type){
		case ProfileTypes.GET_PROFILE:
			return {
				...state,
				data: action.payload
			}

		case ProfileTypes.SET_LOADING_PROFILE:
			return {
				...state,
				isLoadingProfile: action.payload
			}

		case ProfileTypes.SET_ERROR_PROFILE:
			return {
				...state,
				error: action.payload
			}

		default:
			return state;
	}
}