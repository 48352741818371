import { useContext, createContext, useState } from 'react';

interface ProviderProps {
	children: any;
}
interface ContextProps {
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<ContextProps>({isLoading:false, setIsLoading: ()=>{}});
export const LoadingProvider:React.FC<ProviderProps> = ({children}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	return (
		<LoadingContext.Provider value={{isLoading, setIsLoading}}>
			{children}
		</LoadingContext.Provider>
	);
}

const useLoading = ()=>{
	const {isLoading, setIsLoading} = useContext(LoadingContext);
	return {isLoading, setIsLoading};
}
export default useLoading;