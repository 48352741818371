import { AdminMetadata } from './adminMetadata.type';

export enum EAroReferralType {
	REFERRAL = 'referral-discount',
	NON_REFERRAL = 'non-referral-discount'
}

export interface IAroReferral {
	aroReferralId?: string
	aroId?: string
	discountNote: string
	discountFormula: string
	aroReferralType: EAroReferralType
	adminMetadata?: AdminMetadata
	updatedAt?: Date
	createdAt?: Date
}