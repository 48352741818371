import {
	lazy,
	useEffect,
	useState,
	Suspense
} from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import RouteGuard from './RouteGuard';

const Login = lazy(()=>import('./pages/Login'));
const Profile = lazy(()=>import('./pages/Profile'));

const Stock = lazy(()=>import('./pages/Stock/Stock'));
const StockHistory = lazy(()=>import('./pages/Stock/StockHistory'));
const AddStockProduct = lazy(()=>import('./pages/Stock/AddStockProduct'));

const Transaction = lazy(()=>import('./pages/Transaction/Transaction'));
const TransactionDetail = lazy(()=>import('./pages/Transaction/TransactionDetail'));

const Disbursement = lazy(()=>import('./pages/Disbursement/Disbursement'));

const Router: React.FC=()=>{
	const auth=useAuth();
	const [isLoaded,setIsLoaded] = useState(false);

	useEffect(()=>{
		if(isLoaded) return ;
		auth.checkUserSignIn(()=>setIsLoaded(true));
	},[isLoaded, auth]);

	return isLoaded?(
		<Suspense fallback={<></>}>
			<BrowserRouter>
				<Routes>
					<Route element={<RouteGuard isBlocked={auth.isSignIn} redirectPath="transaction"/>}>
						<Route index element={<Login/>}/>
						<Route path="login" element={<Login/>}/>
					</Route>
					<Route element={<RouteGuard isBlocked={!auth.isSignIn} withLayout={true}/>}>
						<Route index element={<Navigate to="transaction" replace/>}/>
						<Route path="profile" element={<Profile/>}/>
						<Route path="stock" element={<Stock/>}/>
						<Route path="stock/add" element={<AddStockProduct/>}/>
						<Route path="stock/:productId" element={<StockHistory/>}/>
						<Route path="transaction" element={<Transaction/>}/>
						<Route path="transaction/:transactionId" element={<TransactionDetail/>}/>
						<Route path="disbursement" element={<Disbursement/>}/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	):<></>;
}
export default Router;