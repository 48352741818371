import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	ChevronUp,
	ChevronDown,
  Grid,
  User
} from 'react-feather';
import {
	Link
} from 'react-router-dom';
import {
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
  Collapse,
  ListSubheader
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import COLORS from 'styles/colors';
import Disburse from 'components/icons/Disburse';

const CustomListSubheader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2, 0, 2),
      color: COLORS.grey,
      fontSize: '12px',
      lineHeight: '14px',
      paddingTop: theme.spacing(1),
      borderTop: '1px solid #545454',
      paddingBottom: theme.spacing(1),
    },
  })
)(ListSubheader);

const CustomListItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.75, 2, 0.75, 2),
      '&:focus, &:active': {
        borderStyle: 'solid',
        borderLeftWidth: 3,
        borderLeftColor: COLORS.greyLight,
        backgroundColor: '#373333',
      },
      '&:hover': {
        backgroundColor: '#2f2b2b',
      },
    },
  })
)(ListItem);

const CustomListSubItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.8, 2, 0.8, 9),
      // paddingLeft: 75,
      '&:hover, &:focus, &:active': {
        backgroundColor: '#444040',
      },
    },
  })
)(ListItem);


const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  icon: {
    color: theme.palette.common.white,
    textTransform: 'none',
    textDecoration: 'none',
  },
  text: {
    color: theme.palette.common.white,
    textTransform: 'none',
    textDecoration: 'none',
  },
  listItemSelected: {
    borderStyle: 'solid',
    borderLeftWidth: 3,
    borderLeftColor: COLORS.greyLight,
    backgroundColor: '#373333',
  },
  listItem: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSelected: {
    backgroundColor: '#444040',
    paddingLeft: theme.spacing(4),
  },
  listRootSidebar: {
    padding: 0,
  },
}));

const LinkItem = (props: {
  indicator?: boolean;
  title: string;
  to?: any;
  iconComponent: any;
  selected?: boolean;
  subitems?: Array<{
    title: string;
    to: any;
    selected?: boolean;
  }>;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.selected);

  if (props.subitems && props.subitems.length > 0) {
    return (
      <>
        <CustomListItem
          button
          className={
            props.selected ? classes.listItemSelected : classes.listItem
          }
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon className={classes.icon}>
            {props.iconComponent}
          </ListItemIcon>
          <ListItemText primary={props.title} />
          {props.indicator && <div style={{
            width: 5,
            height: 5,
            borderRadius: 100,
            backgroundColor: COLORS.warn
          }} />}
          {open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </CustomListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.subitems.map((item, key) => (
              <Link key={key} to={item.to} className={classes.link}>
                <CustomListSubItem
                  button
                  className={
                    item.selected ? classes.nestedSelected : classes.nested
                  }
                >
                  <ListItemText primary={item.title} />
                </CustomListSubItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <Link to={props.to} className={classes.link}>
        <CustomListItem
          button
          className={
            props.selected ? classes.listItemSelected : classes.listItem
          }
        >
          <ListItemIcon className={classes.icon}>
            {props.iconComponent}
          </ListItemIcon>
          <ListItemText className={classes.text} primary={props.title} />
          {props.indicator && <div style={{
            width: 8,
            height: 8,
            borderRadius: 100,
            backgroundColor: COLORS.warn
          }} />}
        </CustomListItem>
      </Link>
    );
  }
};

const Sidebar: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();

	return (
		<div id="scrollbar-sidebar">
			<List className={classes.listRootSidebar}>
        <List subheader={<CustomListSubheader disableSticky>Agent Transaction</CustomListSubheader>}>
          <LinkItem
            title="Transaction"
            to="/transaction"
            iconComponent={<Disburse size={22} color={COLORS.accent} />}
            selected={location.pathname.includes('/transaction')}
          />
        </List>
        <List subheader={<CustomListSubheader disableSticky>Product</CustomListSubheader>}>
          <LinkItem
            title="Stock"
            to="/stock"
            iconComponent={<Grid size={22} />}
            selected={location.pathname.includes('/stock')}
          />
        </List>
        <List subheader={<CustomListSubheader disableSticky>Profile</CustomListSubheader>}>
          <LinkItem
            title="Profile"
            to="/profile"
            iconComponent={<User size={22} />}
            selected={location.pathname.includes('/profile')}
          />
        </List>
        <List subheader={<CustomListSubheader disableSticky>Disbursement</CustomListSubheader>}>
          <LinkItem
            title="Disbursement"
            to="/disbursement"
            iconComponent={<Disburse size={22} color={COLORS.accent} />}
            selected={location.pathname.includes('/disbursement')}
          />
        </List>
			</List>
		</div>
	);
}
export default Sidebar;