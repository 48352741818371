import React, { useState, useContext, createContext } from "react";
import { saveToken, getToken, removeToken } from 'helpers/auth';
import { ICustomer } from 'types/customer.type';
import { getMyInfo } from 'providers/customer';
import { apiSignIn, apiVerifyOTP, apiSignOut } from 'providers/auth';

type InitialAuth = {
	user: ICustomer;
	loading: boolean;
	signOutLoading: boolean;
	isSignIn: boolean;
	currentSessionId: string;
	signIn: (phoneNumber: string, callback?: ()=>void) => void;
	verifyOTP: (otp: string, callback?: ()=>void) => void;
	signOut: (callback?: ()=>void) => void;
	checkUserSignIn: (callback?: ()=>void) => void;
}

const initialState: InitialAuth = {
	user:{
		customerName:'',
		customerType:'',
		phoneNumber:'',
		status: 'active'
	},
	loading: false,
	signOutLoading: false,
	isSignIn: false,
	currentSessionId: '',
	signIn: (phoneNumber: string) => console.info('oo'),
	verifyOTP: (otp: string) => console.info('oo'),
	signOut: (callback?: ()=>void) => console.log('oo'),
	checkUserSignIn: (callback?: ()=>void)=> console.info('oo')
}

const AuthContext = createContext(initialState);

export const AuthProvider = ({children}: any)=>{
	const auth = useProvideAuth();

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = ()=>{
	return useContext(AuthContext);
}

const useProvideAuth=()=>{
	const [loading, setLoading] = useState<boolean>(false);
	const [user, setUser] = useState<ICustomer>(initialState.user);
	const [isSignIn, setIsSignIn] = useState<boolean>(false);
	const [signOutLoading, setSignOutLoading] = useState<boolean>(false);
	const [currentSessionId, setCurrentSessionId] = useState<string>('');

	const fetchMyProfile = async()=>{
		const {customerName, status, phoneNumber, customerType, customerRole, distributorId, profilePic}= await getMyInfo();
		if(customerType!=='aro'){
			setUser(initialState.user);
			setIsSignIn(false);
			return removeToken();
		}

		const currentValue = {
			customerName,
			status,
			phoneNumber,
			customerType,
			customerRole,
			distributorId,
			profilePic
		}

		setUser(currentValue);
		setIsSignIn(true);
	}

	const signIn = async(phoneNumber: string, callback?: any)=>{
		try {
			setLoading(true);
			const result = await apiSignIn({phoneNumber});

			if (result && result.data && result.data.payload) {
				const { sessionId } = result.data.payload;
				setCurrentSessionId(sessionId);
				setLoading(false);
				if(callback) callback(result.data.payload);
			}
		} catch (error) {
			setLoading(false);
		}
	}

	const verifyOTP = async(otp: string, callback?: any)=>{
		try {
			setLoading(true);
			const result = await apiVerifyOTP({
				sessionId: currentSessionId,
				otp
			});

			if (result && result.data && result.data.payload && result.data.payload.token) {
				const { token } = result.data.payload;

				await saveToken(token);

				await fetchMyProfile();

		        setLoading(false)

		        if (callback && result.data.code && result.data.code === 'success') {
		          callback();
		        }

			}
		} catch (error) {
			setLoading(false);
		}
	}

	const signOut = async(callback?: any)=>{
		try {
			setSignOutLoading(true);
			await apiSignOut();
			await removeToken();
			if(callback) callback();
			setSignOutLoading(false);
		} catch (error) {
			setSignOutLoading(false);
		}
	}

	const checkUserSignIn = async(callback?: any) => {
	    setLoading(true)

	    let token = await getToken();
	    if (token) {
			if (!user || !user.customerName) {
				await fetchMyProfile()
			}
			setLoading(false);
	    } else {
			setLoading(false)
			setIsSignIn(false)
			setUser(initialState.user);
			if (window.location.pathname !== "/") {
				window.location.href = "/";
			}
	    }
	    if(callback) callback();
	}

	return {
		user,
		loading,
		signOutLoading,
		isSignIn,
		currentSessionId,
		signIn,
		verifyOTP,
		signOut,
		checkUserSignIn
	};

}
export default useAuth;