import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Router from './Router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'styles/theme';
import { AuthProvider } from 'hooks/useAuth';
import { LoadingProvider } from 'hooks/useLoading';

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LoadingProvider>
            <ToastContainer position="top-center" />
            <Router/>
          </LoadingProvider>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
