import { ISingleAro } from 'types/aro.type';

export interface IProfileState {
	isLoadingProfile: boolean;
	data: ISingleAro;
	error: any;
}
export enum ProfileTypes {
	GET_PROFILE = 'GET_PROFILE',
	SET_LOADING_PROFILE = 'SET_LOADING_PROFILE',
	SET_ERROR_PROFILE = 'SET_ERROR_PROFILE'
}