import {
	Suspense,
	useEffect
} from 'react';
import {
	Navigate,
	Outlet
} from 'react-router-dom';
import Layout from 'components/Layout';
import useLoading from 'hooks/useLoading';

export interface RouteGuardProps {
	isBlocked: boolean;
	redirectPath?: string;
	withLayout?: boolean;
}

const LazyLoad: React.FC = ()=>{
	const {setIsLoading} = useLoading();

	useEffect(()=>{
		setIsLoading(true);

		return ()=>{
			setIsLoading(false);
		}
	});
	return <></>;
}

const RouteGuard: React.FC<RouteGuardProps> = ({isBlocked, redirectPath = 'login', withLayout=false})=>{
	if(isBlocked){
		return <Navigate to={redirectPath} replace/>;
	}

	if(withLayout){
		return <Layout><Suspense fallback={<LazyLoad/>}><Outlet/></Suspense></Layout>;
	}

	return <Outlet/>;
}
export default RouteGuard;