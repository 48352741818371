import {
  Button,
  ButtonProps,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import COLORS from 'styles/colors';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

interface IButton extends ButtonProps {
  to?: any;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export default function AppButton(props: IButton) {
  let additionalProps: any = {};
  Object.keys(props).forEach((key) => {
    if (
      key !== 'to' &&
      key !== 'onClick' &&
      key !== 'loading' &&
      key !== 'disabled'
    ) {
      additionalProps[key] = (props as any)[key];
    }
  });
  const classes = useStyles();

  if (props.loading) {
    return (
      <CustomButton
        disabled={props.disabled}
        className={
          props.color === 'secondary' ? classes.secondary : classes.primary
        }
        style={{ width: props.fullWidth ? '100%' : 'auto' }}
        children={<CircularProgress color="secondary" size="22px" />}
      />
    );
  } else if (props.disabled) {
    return (
      <CustomButton
        disabled={props.disabled}
        className={classes.disabled}
        {...additionalProps}
      />
    );
  } else if (props.to) {
    return (
      <Link to={props.to}>
        <CustomButton
          className={
            props.color === 'secondary' ? classes.secondary : classes.primary
          }
          {...additionalProps}
        />
      </Link>
    );
  } else if (props.onClick) {
    return (
      <CustomButton
        onClick={() =>
          props.onClick ? props.onClick() : console.info('nothing')
        }
        disabled={props.disabled}
        className={
          props.color === 'secondary' ? classes.secondary : classes.primary
        }
        {...additionalProps}
      />
    );
  } else {
    return (
      <CustomButton
        disabled={props.disabled}
        className={
          props.color === 'secondary' ? classes.secondary : classes.primary
        }
        {...additionalProps}
      />
    );
  }
}

const CustomButton = withStyles({
  root: {

  },
})(Button);

const useStyles = makeStyles({
  primary: {
    color: COLORS.accent,
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: 10,
    textTransform: 'none',
    minWidth: 100,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      opacity: 0.7,
      color: COLORS.accent,
      backgroundColor: COLORS.primary,
    },
  },
  secondary: {
    color: COLORS.primary,
    backgroundColor: COLORS.accent,
    borderColor: COLORS.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: 10,
    textTransform: 'none',
    minWidth: 100,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      opacity: 0.7,
      color: COLORS.primary,
      backgroundColor: COLORS.accent,
    },
  },
  disabled: {
    color: COLORS.greyDark,
    backgroundColor: COLORS.greyLight,
    borderColor: COLORS.greyDark,
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: 10,
    textTransform: 'none',
    minWidth: 100,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      opacity: 0.7,
      color: COLORS.greyDark,
      backgroundColor: COLORS.greyLight,
    },
  },
});
